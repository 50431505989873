import { useState } from "react";

function Notification({ message }) {
  return <div className="text-xs notification">{message}</div>;
}

export default function SaveTimelineButton({
  projectName,
  startDate,
  endDate,
  excludeWeekends,
}) {
  const [showNotification, setShowNotification] = useState(false);
  const handleSave = () => {
    // Stringify the timeline data
    const timelineData = {
      projectName,
      startDate,
      endDate,
      excludeWeekends,
    };

    // Get the existing projects from localStorage
    const existingProjects = JSON.parse(localStorage.getItem("projects")) || [];

    // Add the new timeline data to the existing projects
    const updatedProjects = [...existingProjects, timelineData];

    // Save the updated projects to localStorage
    localStorage.setItem("projects", JSON.stringify(updatedProjects));

    // Retrieve the projects from localStorage
    const storedProjects = JSON.parse(localStorage.getItem("projects"));

    // Check if the last project in the stored projects is the same as the timeline data
    const lastStoredProject = storedProjects[storedProjects.length - 1];
    const isSetCorrectly =
      JSON.stringify(lastStoredProject) === JSON.stringify(timelineData);

    if (isSetCorrectly) {
      setShowNotification(true);
      setTimeout(() => setShowNotification(false), 2000); // Hide after 2 seconds
    } else {
      console.error("Failed to save timeline data to localStorage");
    }

    setShowNotification(true);
    setTimeout(() => setShowNotification(false), 2000); // Hide after 2 seconds
  };

  return (
    <div className="flex gap-3 items-center">
      {showNotification && <Notification message="Timeline saved" />}
      <button
        type="button"
        onClick={handleSave}
        className="text-xs text-indigo-500 border border-indigo-500 rounded p-1"
      >
        Save this timline to your device
      </button>
    </div>
  );
}
