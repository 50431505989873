import lzString from "lz-string";
import TimelineMeter from "./TimelineMeter";
import TimelineSummary from "./TimelineSummary";
import { useState } from "react";
import SaveTimelineButton from "./SaveTimelineButton";
import TaskList from "./TaskList";
import QuickAddTask from "./QuickAddTask";

function Notification({ message }) {
  return <div className="text-xs notification">{message}</div>;
}

function TaskListByIds({ taskIds }) {
  const [showTasks, setShowTasks] = useState(true);

  if (!taskIds) {
    return null;
  }

  const tasks = JSON.parse(localStorage.getItem("tasks")) || {};
  const timelineTasks = taskIds.reduce((acc, id) => {
    acc[id] = tasks[id];
    return acc;
  }, {});

  const completedTasks = Object.values(timelineTasks).filter(
    (task) => task?.isComplete
  ).length;

  return (
    <div className="flex flex-col gap-2 mb-2">
      <h3>
        Tasks ({completedTasks} of {taskIds.length} completed)
      </h3>
      <button onClick={() => setShowTasks(!showTasks)}>
        {showTasks ? "Hide Tasks" : "Show Tasks"}
      </button>
      {showTasks && <TaskList tasks={tasks} />}
    </div>
  );
}

function getAssignedTasks(timelineIds) {
  if (!timelineIds) {
    return [];
  }

  const allTasks = JSON.parse(localStorage.getItem("tasks")) || {};
  return Object.values(allTasks).filter((task) =>
    timelineIds.includes(task.timeline)
  );
}

export default function Timeline({
  days,
  daysTillProjectStart,
  description,
  disableEdit,
  displaySaveButton,
  end,
  endDate,
  excludeWeekends,
  index,
  id,
  isToday,
  name,
  onDelete,
  pastDays,
  pastDaysExcludingWeekends,
  projectEnded,
  projectStarted,
  start,
  startDate,
  tasks: initialTaskIds,
  totalDays,
  totalDaysExcludingWeekends,
}) {
  const [taskIds, setTaskIds] = useState(initialTaskIds);
  const [tasks, setTasks] = useState(getAssignedTasks(taskIds));
  const [showNotification, setShowNotification] = useState(false);
  const [openIndex, setOpenIndex] = useState();
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const updateTasks = () => {
    const allTasks = JSON.parse(localStorage.getItem("tasks")) || {};
    const timelineTasks = Object.values(allTasks).filter(
      (task) => task.timeline === id
    );
    setTasks(timelineTasks);
    setTaskIds(timelineTasks.map((task) => task.id));
  };

  const handleShare = () => {
    // Stringify and compress the timeline data
    const timelineData = {
      endDate,
      excludeWeekends,
      name,
      startDate,
    };
    const compressedData = lzString.compressToEncodedURIComponent(
      JSON.stringify(timelineData)
    );

    // Create the shareable URL
    const url = `${window.location.origin}/timeline/shared?data=${compressedData}`;

    navigator.clipboard.writeText(url).then(
      () => {
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 2000); // Hide after 2 seconds
      },
      (error) => {
        console.error("Could not copy text: ", error);
      }
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        {name}
        <div className="flex gap-3">
          {description && (
            <button
              className="text-xs text-indigo-500 border border-indigo-500 rounded px-2"
              type="button"
              onClick={() => toggleAccordion(index)}
            >
              Description
            </button>
          )}
          {showNotification && (
            <Notification message="Link copied to clipboard!" />
          )}
          <button
            type="button"
            onClick={handleShare}
            className="text-xs text-indigo-500 border border-indigo-500 rounded p-1"
          >
            Share
          </button>
        </div>
      </div>

      {openIndex === index && ( // Only render this div if this is the open accordion
        <p className="px-4 text-gray-300">{description}</p>
      )}
      <div className="flex flex-col gap-2">
        <TimelineMeter
          days={days}
          end={end}
          excludeWeekends={excludeWeekends}
          isToday={isToday}
          pastDays={pastDays}
          pastDaysExcludingWeekends={pastDaysExcludingWeekends}
          start={start}
        />
        <TimelineSummary
          daysTillProjectStart={daysTillProjectStart}
          pastDays={pastDays}
          pastDaysExcludingWeekends={pastDaysExcludingWeekends}
          projectEnded={projectEnded}
          projectStarted={projectStarted}
          totalDays={totalDays}
          totalDaysExcludingWeekends={totalDaysExcludingWeekends}
          excludeWeekends={excludeWeekends}
        />
        {taskIds?.length > 0 && <TaskListByIds taskIds={taskIds} />}
        <QuickAddTask timelineId={id} updateTasks={updateTasks} />
      </div>
      <div className="flex justify-end gap-2">
        {onDelete && (
          <button
            type="button"
            onClick={() => onDelete(index)}
            className="text-xs text-red-500 border border-red-500 rounded p-1"
          >
            Delete
          </button>
        )}

        {!disableEdit && (
          <a
            href={`/timelines/${index}/edit`}
            className="text-xs text-indigo-500 border border-indigo-500 rounded p-1"
          >
            Edit
          </a>
        )}

        {displaySaveButton && (
          <SaveTimelineButton
            endDate={endDate}
            excludeWeekends={excludeWeekends}
            projectName={name}
            startDate={startDate}
          />
        )}
      </div>
    </div>
  );
}
