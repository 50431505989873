import React, { useState } from "react";
import { TEXT_CONSTANTS } from "./textConstants";
import { Form, redirect, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export async function action({ request }) {
  const formData = await request.formData();
  let formDataObj = Object.fromEntries(formData);

  formDataObj = {
    ...formDataObj,
    excludeWeekends: formDataObj.excludeWeekends === "on",
    id: uuidv4(),
    creationDate: new Date().toISOString(),
  };

  // Retrieve existing projects from local storage
  const existingProjects = JSON.parse(localStorage.getItem("projects")) || [];
  // Append new project to the existing projects list
  const updatedProjects = [...existingProjects, formDataObj];
  // Save updated projects list to local storage
  localStorage.setItem("projects", JSON.stringify(updatedProjects));
  return redirect("/");
}

const ProjectForm = () => {
  const navigate = useNavigate();
  const [isExcludingWeekends, setIsExcludingWeekends] = useState();

  return (
    <Form method="POST" className="p-4 flex flex-col gap-5">
      <div>
        <label htmlFor="projectName" className="block">
          Timeline Name:
        </label>
        <input
          type="text"
          id="projectName"
          className="w-full mt-1 p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          required
          name="projectName"
        />
      </div>
      <div>
        <label htmlFor="projectDescription" className="block">
          Timeline Description:
        </label>
        <textarea
          id="projectDescription"
          className="w-full mt-1 p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          name="projectDescription"
        />
      </div>
      <div className="flex flex-row gap-2">
        <div className="flex-grow">
          <label htmlFor="startDate" className="block">
            Start Date:
          </label>
          <input
            type="date"
            id="startDate"
            className="w-full mt-1 p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            required
            name="startDate"
          />
        </div>
        <div className="flex-grow">
          <label htmlFor="endDate" className="block">
            End Date:
          </label>
          <input
            type="date"
            id="endDate"
            className="w-full mt-1 p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            required
            name="endDate"
          />
        </div>
      </div>
      <div className="flex gap-2">
        <label htmlFor="excludeWeekends" className="block">
          Exclude Weekends:
        </label>
        <input
          type="checkbox"
          id="excludeWeekends"
          className="mt-1"
          name="excludeWeekends"
          onChange={(e) => {
            setIsExcludingWeekends(e.target.checked);
          }}
        />
      </div>
      {isExcludingWeekends && (
        <p>{TEXT_CONSTANTS.descriptions.excludeWeekends}</p>
      )}
      <div className="flex gap-2">
        <button
          type="button"
          onClick={() => navigate("/")}
          className="w-full bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="w-full bg-red-400 hover:bg-red-500 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Create
        </button>
      </div>
    </Form>
  );
};

export default ProjectForm;
