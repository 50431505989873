import { useEffect, useRef } from "react";
import Icon from "./Icon";
import { Link } from "react-router-dom";

const SideMenu = ({ isOpen, setIsOpen, toggleMenu }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className="relative">
      <div
        className={`fixed inset-0 z-10 transition-all duration-300 ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div
          className={`absolute inset-0 bg-gray-900 opacity-50 transition-opacity duration-300 ${
            isOpen ? "opacity-50" : "opacity-0"
          }`}
          onClick={toggleMenu}
        ></div>
        <div
          ref={menuRef}
          className={`absolute inset-y-0 left-0 w-64 bg-indigo-950 shadow-lg transition-transform duration-300 transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } overflow-y-auto`}
        >
          <div className="">
            {/* Your menu content goes here */}
            <div className="flex items-center gap-3 pl-3 pt-4 box-border">
              <button
                className="text-white focus:outline-none hover:bg-slate-900 rounded-full p-1"
                onClick={toggleMenu}
              >
                <svg
                  className={`w-6 h-6 transition-transform duration-300 ${
                    isOpen ? "transform rotate-180" : ""
                  }`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6H20M4 12H20M4 18H20"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <h1 className="text-lg font-bold">Bird's Eye Plan</h1>
            </div>
            <nav>
              <ul className="flex flex-col pt-4">
                <li className="px-2">
                  <Link
                    to={"/"}
                    onClick={toggleMenu}
                    className="flex gap-2 py-2 pl-2 rounded items-center hover:bg-slate-900"
                  >
                    <Icon name="calendar" width="18" height="18" />
                    Timelines
                  </Link>
                </li>
                <li className="px-2">
                  <Link
                    to={"/tasks"}
                    onClick={toggleMenu}
                    className="flex gap-2 pl-2 items-center rounded py-2 hover:bg-slate-900"
                  >
                    <Icon name="list" width="18" height="18" />
                    Tasks
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
