import React from "react";

const Checkbox = ({ id, checked, onChange }) => {
  return (
    <div className="custom-checkbox">
      <input type="checkbox" checked={checked} onChange={onChange} id={id} />
      <label htmlFor={id}>
        <svg width="20px" height="20px" viewBox="0 0 20 20">
          <polyline points="5.5 11 8.5 14 14.5 6" />
        </svg>
      </label>
      <style jsx="true">{`
        .custom-checkbox {
          position: relative;
          display: inline-block;
        }
        input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .custom-checkbox label {
          position: relative;
          width: 20px;
          height: 20px;
          border: 2px solid #fff;
          display: inline-block;
          cursor: pointer;
        }
        .custom-checkbox label svg {
          position: absolute;
          top: 0;
          left: 0;
          fill: none;
          stroke: #fff;
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 16px;
          stroke-dashoffset: 16px;
          transition: all 0.3s ease-in-out;
        }
        input[type="checkbox"]:checked + label svg {
          stroke-dashoffset: 0;
        }
      `}</style>
    </div>
  );
};

export default Checkbox;
