import Button from "./Button";
import { Outlet, Link } from "react-router-dom";

import SideMenu from "./SideMenu";
import { useState } from "react";

export default function MainArea() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-col h-screen bg-indigo-950">
      <div className="flex justify-between w-full pt-4 px-4">
        <div className="flex items-center gap-3">
          <button
            className="text-white focus:outline-none hover:bg-slate-900 rounded-full p-1"
            onClick={toggleMenu}
          >
            <svg
              className={`w-6 h-6 transition-transform duration-300 ${
                isOpen ? "transform rotate-180" : ""
              }`}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6H20M4 12H20M4 18H20"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <Link to={"/"}>
            <h1 className="text-lg font-bold">Bird's Eye Plan</h1>
          </Link>
        </div>
        <Link to={"/projects/new"}>
          <Button
            className="ml-auto text-sm p-2 rounded border border-solid border-white"
            variant="primary"
          >
            New Timeline
          </Button>
        </Link>
      </div>
      <div className="flex flex-grow overflow-hidden">
        <SideMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          toggleMenu={toggleMenu}
        />
        <div className="flex-grow p-4 overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
