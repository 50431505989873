import { useState, useEffect } from "react";
import Timeline from "./Timeline";
import TimelineListEmpty from "./TimelineListEmpty";
import createTimeline from "./factories/timeline";
import { useLoaderData, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import Dropdown from "./Dropdown";

export async function loader({ params }) {
  const storedProjects = JSON.parse(localStorage.getItem("projects")) || [];
  const timelines = storedProjects.map((project) => {
    return createTimeline(project);
  });

  return { timelines };
}

const ProjectList = () => {
  const { timelines: loaderTimeLines } = useLoaderData();
  const navigate = useNavigate();
  const location = useLocation();

  const { sort = "default" } = queryString.parse(location.search);
  const [sortOption, setSortOption] = useState(sort);
  const { filter = "default" } = queryString.parse(location.search);
  const [filterOption, setFilterOption] = useState(filter);
  const [timelines, setTimelines] = useState(loaderTimeLines);

  useEffect(() => {
    // Fetch and sort timelines here...
    let filteredTimelines = [...loaderTimeLines];

    switch (filterOption) {
      case "started":
        filteredTimelines = filteredTimelines.filter(
          (timeline) => timeline.projectStarted && !timeline.projectEnded
        );
        break;
      case "ended":
        filteredTimelines = filteredTimelines.filter(
          (timeline) => timeline.projectEnded
        );
        break;
      case "planned":
        filteredTimelines = filteredTimelines.filter(
          (timeline) => !timeline.projectEnded && !timeline.projectStarted
        );
        break;
      default:
      // No filter applied
    }

    let sortedTimelines = filteredTimelines;

    switch (sortOption) {
      case "dateEndingSoonest":
        sortedTimelines = sortedTimelines.sort((a, b) => a.end - b.end);
        break;
      case "dateStartingSoonest":
        sortedTimelines = sortedTimelines.sort((a, b) => a.start - b.start);
        break;
      case "lengthLowest":
        sortedTimelines = sortedTimelines.sort(
          (a, b) =>
            (a.excludeWeekends ? a.totalDaysExcludingWeekends : a.totalDays) -
            (b.excludeWeekends ? b.totalDaysExcludingWeekends : b.totalDays)
        );
        break;
      case "lengthHighest":
        sortedTimelines = sortedTimelines.sort(
          (a, b) =>
            (b.excludeWeekends ? b.totalDaysExcludingWeekends : b.totalDays) -
            (a.excludeWeekends ? a.totalDaysExcludingWeekends : a.totalDays)
        );
        break;
      default:
      // No sort applied
    }
    setTimelines(sortedTimelines);
  }, [sortOption, filterOption, loaderTimeLines]);

  useEffect(() => {
    const handleFocus = () => {
      // Refresh data when tab is focused
      navigate(`${location.pathname}${location.search}`, { replace: true });
    };

    // Attach the focus event listener
    window.addEventListener("focus", handleFocus);

    // Detach the focus event listener when the component is unmounted
    return () => window.removeEventListener("focus", handleFocus);
  });

  useEffect(() => {
    if (timelines.length > 0) {
      // Create a URLSearchParams object from the current query string
      const params = new URLSearchParams(location.search);

      // Add or update the 'sort' and 'filter' query parameters
      if (sortOption === "default") {
        params.delete("sort");
      } else {
        params.set("sort", sortOption);
      }

      if (filterOption === "default") {
        params.delete("filter");
      } else {
        params.set("filter", filterOption);
      }

      // Navigate to the current path with the updated query string
      navigate(`${location.pathname}${params.size > 0 ? `?${params}` : ""}`, {
        replace: true,
      });
    }
  }, [
    sortOption,
    filterOption,
    timelines.length,
    location.search,
    location.pathname,
    navigate,
  ]);

  function handleDelete(index) {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this timeline?"
    );
    if (confirmDelete) {
      // Get the current list of items from local storage
      const items = JSON.parse(localStorage.getItem("projects")) || [];
      // Remove the item at the specified index
      const selectedToDelete = timelines[index];
      // find in items by name and remove
      const filteredItems = items.filter(
        (item) => item.projectName !== selectedToDelete.name
      );
      // Save the updated list of items back to local storage
      localStorage.setItem("projects", JSON.stringify(filteredItems));
      const newItems = [
        ...timelines.slice(0, index),
        ...timelines.slice(index + 1),
      ];
      setTimelines(newItems);
    }
  }

  const shouldDisplayEmptyState =
    loaderTimeLines.length === 0 &&
    sortOption === "default" &&
    filterOption === "default";

  return (
    <div className="pb-6 px-4">
      {shouldDisplayEmptyState && <TimelineListEmpty />}

      {!shouldDisplayEmptyState && (
        <div className="flex flex-col gap-3">
          <div className="flex justify-end">
            <div className="flex gap-3">
              <Dropdown
                options={[
                  { value: "default", text: "Default" },
                  { value: "dateStartingSoonest", text: "Date: Start Soonest" },
                  { value: "dateEndingSoonest", text: "Date: End Soonest" },
                  { value: "lengthLowest", text: "Length: Lowest" },
                  { value: "lengthHighest", text: "Length: Highest" },
                ]}
                value={sortOption}
                onChange={setSortOption}
                prefix="Sort: "
              />
              <Dropdown
                options={[
                  { value: "default", text: "All" },
                  { value: "started", text: "Started" },
                  { value: "ended", text: "Ended" },
                  { value: "planned", text: "Planned" },
                ]}
                value={filterOption}
                onChange={setFilterOption}
                prefix={"Show: "}
              />
            </div>
          </div>

          <ul className="flex flex-col gap-5">
            {timelines.map(
              (
                {
                  days,
                  daysTillProjectStart,
                  description,
                  end,
                  endDate,
                  excludeWeekends,
                  isToday,
                  name,
                  pastDays,
                  pastDaysExcludingWeekends,
                  projectEnded,
                  projectStarted,
                  start,
                  startDate,
                  tasks,
                  totalDays,
                  totalDaysExcludingWeekends,
                  id,
                },
                index
              ) => (
                <li key={id} className="border border-indigo-900 rounded p-6">
                  <Timeline
                    days={days}
                    daysTillProjectStart={daysTillProjectStart}
                    description={description}
                    end={end}
                    endDate={endDate}
                    excludeWeekends={excludeWeekends}
                    index={index}
                    id={id}
                    isToday={isToday}
                    name={name}
                    onDelete={handleDelete}
                    pastDays={pastDays}
                    pastDaysExcludingWeekends={pastDaysExcludingWeekends}
                    projectEnded={projectEnded}
                    projectStarted={projectStarted}
                    start={start}
                    startDate={startDate}
                    tasks={tasks}
                    totalDays={totalDays}
                    totalDaysExcludingWeekends={totalDaysExcludingWeekends}
                  />
                </li>
              )
            )}
          </ul>

          {!shouldDisplayEmptyState && timelines.length === 0 && (
            <div className="flex justify-center">
              {" "}
              No timelines match the selected filter.{" "}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectList;
