import { Form, redirect, useNavigate, useLoaderData } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export async function action({ request, params }) {
  const formData = await request.formData();
  let formDataObj = Object.fromEntries(formData);

  const id = params.taskId ? params.taskId : uuidv4();

  // Retrieve existing tasks from local storage
  const existingTasks = JSON.parse(localStorage.getItem("tasks")) || [];
  const task = existingTasks[id];

  formDataObj = {
    ...formDataObj,
    id,
    timeline: formDataObj.timeline || (task ? task.timeline : null),
    creationDate: task ? task.creationDate : new Date().toISOString(),
  };

  // If the timeline field is an empty string, set it to null
  if (formDataObj.timeline === "") {
    formDataObj.timeline = null;
  }

  // Append new project to the existing tasks list
  const updatedTasks = { ...existingTasks, [id]: formDataObj };
  // Save updated tasks list to local storage
  localStorage.setItem("tasks", JSON.stringify(updatedTasks));

  // If a timeline was selected, add the task id to the timeline
  if (formDataObj.timeline) {
    // Retrieve existing timelines from local storage
    const existingTimelines =
      JSON.parse(localStorage.getItem("projects")) || [];
    const timeline = existingTimelines.find(
      (timeline) => timeline.id === formDataObj.timeline
    );

    if (timeline) {
      timeline.tasks = timeline.tasks || [];
      timeline.tasks.push(id);

      // Save updated timelines list to local storage
      localStorage.setItem("projects", JSON.stringify(existingTimelines));
    }
  }

  return redirect("/tasks");
}

export async function loader({ params }) {
  const tasks = JSON.parse(localStorage.getItem("tasks")) || {};
  const task = tasks[params.taskId];

  const timelines = JSON.parse(localStorage.getItem("projects")) || [];

  return {
    task: task ?? null,
    timelines,
  };
}

const CreateTaskForm = () => {
  const navigate = useNavigate();
  const { task, timelines } = useLoaderData();

  return (
    <Form method="POST" className="p-4 flex flex-col gap-5">
      <div>
        <label htmlFor="taskName" className="block">
          Task:
        </label>
        <input
          type="text"
          id="taskName"
          className="w-full mt-1 p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          required
          name="taskName"
          defaultValue={task ? task.taskName : ""}
        />
      </div>
      <div>
        <label htmlFor="taskDescription" className="block">
          Task Description:
        </label>
        <textarea
          id="taskDescription"
          className="w-full mt-1 p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          name="taskDescription"
          defaultValue={task ? task.taskDescription : ""}
        />
      </div>
      <div className="flex flex-row gap-2">
        <div className="flex-grow">
          <label htmlFor="dueDate" className="block">
            Due Date:
          </label>
          <input
            type="date"
            id="dueDate"
            className="w-full mt-1 p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            name="dueDate"
            defaultValue={task.dueDate ?? ""}
          />
        </div>
        <div className="flex-grow">
          <label htmlFor="timeline" className="block">
            Assign to Timeline:
          </label>
          <select
            id="timeline"
            name="timeline"
            className="w-full mt-1 p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
            defaultValue={task ? task.timeline : ""}
          >
            <option value="">None</option>
            {timelines.map((timeline) => (
              <option key={timeline.id} value={timeline.id}>
                {timeline.projectName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="flex gap-2">
        <button
          type="button"
          onClick={() => navigate("/tasks")}
          className="w-full bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="w-full bg-red-400 hover:bg-red-500 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {task ? "Update" : "Create"} Task
        </button>
      </div>
    </Form>
  );
};

export default CreateTaskForm;
