import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { loader as projectLoader } from "./ProjectDetails";
import ProjectList, { loader as projectListLoader } from "./ProjectList";
import ProjectForm, { action as projectCreatAction } from "./ProjectForm";
import ProjectEditForm, {
  action as editTimelineAction,
} from "./ProjectEditForm";
import ShareTimeLine from "./ShareTimeLine";
import { loader as tasksListLoader } from "./TaskList";
import TaskListContainer from "./TaskListContainer";
import CreateTaskForm, {
  action as taskCreateAction,
  loader as taskLoader,
} from "./CreateTaskForm";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        loader: projectListLoader,
        element: <ProjectList />,
      },
      {
        path: "projects/new",
        action: projectCreatAction,
        element: <ProjectForm />,
      },
      {
        path: "timelines/:timelineId/edit",
        loader: projectLoader,
        action: editTimelineAction,
        element: <ProjectEditForm />,
      },
      {
        path: "timeline/shared",
        element: <ShareTimeLine />,
      },
      {
        path: "tasks",
        loader: tasksListLoader,
        element: <TaskListContainer />,
      },
      {
        path: "tasks/new",
        action: taskCreateAction,
        loader: taskLoader,
        element: <CreateTaskForm />,
      },
      {
        path: "tasks/:taskId/edit",
        action: taskCreateAction,
        loader: taskLoader,
        element: <CreateTaskForm />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
