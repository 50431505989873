import {
  differenceInDays,
  startOfDay,
  isWeekend,
  isToday as isTodayFns,
  eachDayOfInterval,
  parseISO,
} from "date-fns";

export default (project) => {
  const {
    endDate,
    excludeWeekends,
    id,
    projectDescription,
    projectName,
    startDate,
    tasks,
  } = project;
  const start = startOfDay(parseISO(startDate));
  const end = startOfDay(parseISO(endDate));
  const now = startOfDay(new Date()); // not used
  // const isToday = isTodayFns(now); // not used
  const daysTillProjectStart = differenceInDays(start, now);
  const pastDays = differenceInDays(now, start);
  const projectEnded = now >= end;
  const projectStarted = now >= start;
  const totalDays = differenceInDays(end, start);
  const totalDaysExcludingWeekends = excludeWeekends
    ? getWeekdays(start, end)
    : getTotalDays(start, end);

  const days = eachDayOfInterval({ start, end }).map((date) => ({
    date,
    isWeekend: isWeekend(date),
  }));

  return {
    days,
    daysTillProjectStart,
    description: projectDescription,
    end,
    endDate,
    excludeWeekends,
    id,
    isToday: (date) => isTodayFns(date),
    name: projectName,
    now,
    pastDays,
    pastDaysExcludingWeekends: getWeekdays(start, now),
    projectEnded,
    projectStarted,
    start,
    startDate,
    tasks,
    totalDays,
    totalDaysExcludingWeekends,
  };
};

function getTotalDays(start, end) {
  const dateRange = eachDayOfInterval({ start, end });
  return dateRange.length;
}

function getWeekdays(start, end) {
  const dateRange = eachDayOfInterval({ start, end });
  const weekdays = dateRange.filter((date) => isWeekday(date));
  return weekdays.length;
}

function isWeekday(date) {
  return !isWeekend(date);
}
