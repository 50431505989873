import Timeline from "./Timeline";
import createTimeline from "./factories/timeline";
import { useLocation } from "react-router-dom";
import lzString from "lz-string";

const ShareTimeLine = () => {
  const location = useLocation();
  let timeline = undefined;

  try {
    const compressedString = new URLSearchParams(location.search).get("data");
    const jsonString =
      lzString.decompressFromEncodedURIComponent(compressedString);
    let timelineData = JSON.parse(jsonString);
    timelineData = { projectName: timelineData.name, ...timelineData };
    timeline = createTimeline(timelineData);
  } catch (error) {
    return <div>Invalid timeline data</div>;
  }

  const {
    days,
    daysTillProjectStart,
    description,
    end,
    endDate,
    excludeWeekends,
    isToday,
    name,
    pastDays,
    pastDaysExcludingWeekends,
    projectEnded,
    projectStarted,
    start,
    startDate,
    totalDays,
    totalDaysExcludingWeekends,
  } = timeline;

  return (
    <div className="pb-6 px-4">
      <div className="flex flex-col gap-3">
        <h2>Shared Timeline</h2>
        <ul className="flex flex-col gap-5">
          <li className="border border-indigo-900 rounded p-6">
            <Timeline
              days={days}
              daysTillProjectStart={daysTillProjectStart}
              description={description}
              end={end}
              endDate={endDate}
              excludeWeekends={excludeWeekends}
              isToday={isToday}
              name={name}
              pastDays={pastDays}
              pastDaysExcludingWeekends={pastDaysExcludingWeekends}
              projectEnded={projectEnded}
              projectStarted={projectStarted}
              start={start}
              startDate={startDate}
              totalDays={totalDays}
              totalDaysExcludingWeekends={totalDaysExcludingWeekends}
              disableEdit={true}
              displaySaveButton={true}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ShareTimeLine;
