import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Icon from "./Icon";
import Checkbox from "./Checkbox";

export async function loader({ params }) {
  const tasks = JSON.parse(localStorage.getItem("tasks")) || {};
  const timelines = JSON.parse(localStorage.getItem("projects")) || {};
  return { tasks, timelines };
}

const Task = ({ displayAssignedTimeline, task, timelines, updateTasks }) => {
  const [isChecked, setIsChecked] = useState(task?.isComplete || false);

  const handleCheckboxChange = () => {
    // Get the tasks from local storage
    const tasks = JSON.parse(localStorage.getItem("tasks")) || {};
    const id = task.id;

    // Update the isComplete property of the task
    tasks[id].isComplete = !isChecked;

    // Save the updated tasks back to local storage
    localStorage.setItem("tasks", JSON.stringify(tasks));

    // Update the isChecked state
    setIsChecked((prevIsChecked) => !prevIsChecked);

    // Update the tasks state in the TaskList component
    updateTasks(tasks[id]);
  };

  const timelineToDisplay = timelines?.find(
    (timeline) => timeline.id === task.timeline
  );

  return (
    <div
      className={`border border-white/35 flex p-4 rounded-md gap-2 items-baseline ${
        task?.isComplete ? "opacity-50" : ""
      }`}
    >
      <div>
        <Checkbox
          id={task?.id}
          checked={task?.isComplete}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className="flex-grow">
        <h3>{task?.taskName}</h3>
        {task?.taskDescription && <p>{task.taskDescription}</p>}
        {task?.dueDate && <p>Due Date: {task.dueDate}</p>}
        {displayAssignedTimeline && timelineToDisplay && (
          <p>Assigned to timeline: {timelineToDisplay.projectName}</p>
        )}
      </div>
      <div className="flex gap-2 self-center">
        <Link to={`/tasks/${task?.id}/edit`}>
          <Icon name="edit" width={24} height={24} />
        </Link>
        <button
          onClick={() => {
            if (window.confirm("Are you sure you want to delete this task?")) {
              // Get the tasks from local storage
              const tasks = JSON.parse(localStorage.getItem("tasks")) || {};
              const timelines =
                JSON.parse(localStorage.getItem("projects")) || [];

              // Delete the task
              if (task?.id) {
                delete tasks[task?.id];
              }

              // Remove the task from the timeline
              if (task?.timeline) {
                const assignedTimeline = timelines.find(
                  ({ id }) => id === task.timeline
                );

                const timelineTasks = assignedTimeline?.tasks;

                if (timelineTasks?.length > 0) {
                  const timelineIndex = timelines.findIndex(
                    ({ id }) => id === assignedTimeline.id
                  );

                  if (timelineIndex !== -1) {
                    timelines[timelineIndex].tasks = timelineTasks.filter(
                      (taskId) => taskId !== task.id
                    );
                  }
                }
              }

              // Save the updated tasks back to local storage
              localStorage.setItem("tasks", JSON.stringify(tasks));
              localStorage.setItem("projects", JSON.stringify(timelines));

              // Update the tasks state in the TaskList component
              updateTasks(tasks);
            }
          }}
        >
          <Icon name="delete" width={24} height={24} />
        </button>
      </div>
    </div>
  );
};

const TaskList = ({
  tasks: initialTasks,
  displayAssignedTimeline,
  timelines,
}) => {
  const [tasks, setTasks] = useState(initialTasks);
  let taskEntries = Object.entries(tasks);

  useEffect(() => {
    setTasks(initialTasks);
  }, [initialTasks]);

  // Sort tasks so that uncompleted tasks come first
  taskEntries.sort(([, taskA], [, taskB]) => {
    if (taskA?.isComplete === taskB?.isComplete) {
      return 0;
    }
    return taskA?.isComplete ? 1 : -1;
  });

  const updateTasks = (updatedTask) => {
    setTasks((prevTasks) => {
      const newTasks = { ...prevTasks };
      const taskId = updatedTask.id;
      if (newTasks[taskId]) {
        newTasks[taskId] = updatedTask;
      }

      return newTasks;
    });
  };

  return taskEntries.map(([id, task]) => (
    <Task
      key={id}
      task={task}
      updateTasks={updateTasks}
      displayAssignedTimeline={displayAssignedTimeline}
      timelines={timelines}
    />
  ));
};

export default TaskList;
