import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import Icon from "./Icon";

function QuickAddTask({ timelineId, updateTasks, expanded }) {
  const [showForm, setShowForm] = useState(expanded);
  const [showMore, setShowMore] = useState(false);
  const [taskContent, setTaskContent] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [assignedTimeline, setAssignedTimeline] = useState(() =>
    timelineId === "all" || timelineId == "not-assigned" ? "" : timelineId
  );
  const [rows, setRows] = useState(1);
  const timelines = JSON.parse(localStorage.getItem("projects")) || [];
  const textareaRef = useRef(null);

  useEffect(() => {
    if (timelineId !== "all" && timelineId !== "not-assigned") {
      setAssignedTimeline(timelineId);
    } else {
      setAssignedTimeline("");
    }
  }, [timelineId]);

  useEffect(() => {
    if (showForm) {
      textareaRef.current.focus();
    }
  }, [showForm]);

  const handleAdd = () => {
    const tasks = JSON.parse(localStorage.getItem("tasks")) || {};
    const projects = JSON.parse(localStorage.getItem("projects")) || [];
    const newTaskId = uuidv4();
    tasks[newTaskId] = {
      taskName: taskContent,
      creationDate: new Date().toISOString(),
      id: newTaskId,
      isComplete: false,
      ...(!!dueDate && {
        dueDate: dueDate,
      }),
      ...(!!assignedTimeline && {
        timeline: timelineId,
      }),
    };

    const projectIndex = projects.findIndex(
      (project) => project.id === timelineId
    );

    if (projectIndex !== -1) {
      if (!projects[projectIndex].tasks) {
        projects[projectIndex].tasks = [];
      }
      projects[projectIndex].tasks.push(newTaskId);
    }

    localStorage.setItem("tasks", JSON.stringify(tasks));
    localStorage.setItem("projects", JSON.stringify(projects));
    setTaskContent("");
    // setShowForm(false);

    textareaRef.current.focus();

    updateTasks();
  };

  const handleCancel = () => {
    setTaskContent("");
    setShowForm(false);
  };

  const handleChange = (e) => {
    const lineBreaks = (e.target.value.match(/\n/g) || []).length;
    setRows(lineBreaks + 1);
    setTaskContent(e.target.value);
  };

  const isTaskContentEmpty = taskContent.trim() === "";

  return (
    <div className={`${showForm ? "bg-gray-900 p-4 flex flex-col gap-2" : ""}`}>
      <button
        className="flex gap-2 items-center"
        disabled={showForm}
        onClick={() => setShowForm(!showForm)}
      >
        <Icon width={16} height={16} name="add" /> Add Task
      </button>

      {showForm && (
        <div className="flex">
          <textarea
            ref={textareaRef}
            className="bg-indigo-950 resize-none border outline-none rounded-l-md  p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-600"
            value={taskContent}
            onChange={handleChange}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                if (!isTaskContentEmpty) {
                  handleAdd();
                }
              }
            }}
            rows={rows}
            required
          />
          <button
            className="bg-red-400 hover:bg-red-500 rounded-r-md  px-4"
            onClick={handleAdd}
          >
            Add
          </button>
        </div>
      )}

      {showForm && showMore && (
        <div className="flex flex-row gap-2">
          <div className="flex-grow">
            <label htmlFor="dueDate" className="block">
              Due Date:
            </label>
            <input
              type="date"
              id="dueDate"
              className="w-full mt-1 p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            />
          </div>
          {expanded && (
            <div className="flex-grow">
              <label htmlFor="timeline" className="block">
                Assign to Timeline:
              </label>
              <select
                id="timeline"
                name="timeline"
                className="w-full mt-1 p-2 bg-transparent border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                value={assignedTimeline}
                onChange={(e) => setAssignedTimeline(e.target.value)}
              >
                <option value="">None</option>
                {timelines.map((timeline) => (
                  <option key={timeline.id} value={timeline.id}>
                    {timeline.projectName}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}

      <div className="flex gap-3 justify-end">
        <button onClick={() => setShowMore(!showMore)}>
          {showMore ? "Hide" : "Show More"}
        </button>
        {!expanded && showForm && (
          <button onClick={handleCancel}>Cancel</button>
        )}
      </div>
    </div>
  );
}

export default QuickAddTask;
