import React from "react";
import { Link } from "react-router-dom";

export default function TimelineListEmpty() {
  return (
    <>
      <main className="flex flex-col gap-9 container mx-auto px-4 text-red-300">
        <section className="grid grid-cols-2 gap-8 pt-9">
          <div className="flex flex-col justify-center space-x-4">
            <h2 className="text-2xl font-bold text-red-300 mb-4">
              Tired of scrambling?
            </h2>
            <ul className="list-disc space-y-2  text-gray-100">
              <li>Missed deadlines</li>
              <li>Unclear project timelines</li>
              <li>Juggling multiple tasks</li>
            </ul>
          </div>
          <div className="flex flex-col justify-center space-x-4">
            <h2 className="text-2xl font-bold text-red-300 mb-4">
              Bird's Eye Plan to the rescue!
            </h2>
            <ul className="list-disc space-y-2 text-gray-100">
              <li>Avoid deadline surprises</li>
              <li>See your project at a glance</li>
              <li>Manage tasks effortlessly</li>
            </ul>
          </div>
        </section>

        <div className="h-1 border-b border-gray-100/35"></div>

        <section className="">
          <h3 className="text-2xl font-bold text-red-300 mb-8">
            Focus on what matters most, not the mess.
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            <div className="p-6 text-center border border-gray-200 rounded-md">
              <i className="text-blue-500 text-4xl mb-4 fas fa-check-circle"></i>
              <h4 className="text-xl font-bold text-red-300 mb-2">
                Simple & Intuitive
              </h4>
              <p className="text-gray-100">
                Effortless timeline creation in seconds.
              </p>
            </div>
            <div className="p-6 text-center border border-gray-200 rounded-md">
              <i className="text-blue-500 text-4xl mb-4 fas fa-eye"></i>
              <h4 className="text-xl font-bold text-red-300 mb-2">
                Clear Visualization
              </h4>
              <p className="text-gray-100">
                Stay organized with a bird's-eye view of your project.
              </p>
            </div>
            <div className="p-6 text-center border border-gray-200 rounded-md">
              <i className="text-blue-500 text-4xl mb-4 fas fa-rocket"></i>
              <h4 className="text-xl font-bold text-red-300 mb-2">
                Focus on What Matters
              </h4>
              <p className="text-gray-100">
                Reduce stress and focus on getting things done.
              </p>
            </div>
          </div>
        </section>

        {/* <section className="py-12 border-t border-gray-200">
          <h3 className="text-2xl font-bold text-red-300 mb-8">
            See what others are saying:
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <blockquote className="p-6 border border-gray-200 rounded-md">
              <p className="text-gray-100 mb-4">
                “Bird's Eye Plan keeps me on track and helps me meet my
                deadlines!”
              </p>
              <footer className="text-right font-bold text-gray-100">
                - John Smith, Entrepreneur
              </footer>
            </blockquote>
            <blockquote className="p-6 border border-gray-200 rounded-md">
              <p className="text-gray-100 mb-4">
                “Finally, a project management tool that's simple and
                effective!”
              </p>
              <footer className="text-right font-bold text-gray-100">
                - Jane Doe, Marketing Manager
              </footer>
            </blockquote>
          </div>
        </section> */}

        <section className="py-12 text-center flex flex-col gap-8">
          <h2 className="text-3xl text-emerald-300 font-bold">
            Start Your Free Timeline Today!
          </h2>
          <div>
            <Link
              to="/projects/new"
              className="px-4 py-2 border-emerald-300 border rounded-md font-bold text-emerald-300 hover:bg-emerald-300/15 hover:text-emerald-300 transition duration-300 ease-in-out"
            >
              Create Timeline
            </Link>
          </div>
        </section>
      </main>
    </>
  );
}
