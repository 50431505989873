import React from "react";
import { useLoaderData } from "react-router-dom";
import Timeline from "./Timeline";

export async function loader({ params }) {
  const storedProjects = JSON.parse(localStorage.getItem("projects")) || [];
  const timelineIndex = params.timelineId;
  const project = storedProjects[timelineIndex];
  return { project };
}

export default function ProjectDetails({ match }) {
  const { project } = useLoaderData();

  if (!project) {
    return null;
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">{project.projectName}</h2>
      <p>{project.projectDescription}</p>
      <Timeline startDate={project.startDate} endDate={project.endDate} />
    </div>
  );
}
