// import Sidebar from "./Sidebar";
import MainArea from "./MainArea";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { v4 as uuidv4 } from "uuid";

function migrate() {
  // Check if the migration has already been run
  const migrationRan = localStorage.getItem("migrationRan");
  if (migrationRan === "1") {
    return;
  }

  // Get the tasks from local storage
  const timelines = JSON.parse(localStorage.getItem("projects")) || [];

  // Update the schema of each task
  for (let id in timelines) {
    if (!timelines[id].creationDate) {
      timelines[id].creationDate = new Date().toISOString();
    }
    if (!timelines[id].id) {
      timelines[id].id = uuidv4();
    }
  }

  // Save the updated tasks back to local storage
  localStorage.setItem("projects", JSON.stringify(timelines));

  // Set the migration identifier
  localStorage.setItem("migrationRan", 1);
}

export default function Component() {
  migrate();
  return (
    <div className="mx-auto text-white">
      <div className="flex flex-col">
        <MainArea />
      </div>
      <SpeedInsights />
    </div>
  );
}
