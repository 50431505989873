import { useLoaderData } from "react-router-dom";
import TaskList from "./TaskList";
import Dropdown from "./Dropdown";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import QuickAddTask from "./QuickAddTask";
import { set } from "date-fns";

const TaskListContainer = () => {
  const { tasks: initialTasks, timelines } = useLoaderData();
  const [tasks, setTasks] = useState(initialTasks);
  const [searchParams, setSearchParams] = useSearchParams();

  let taskEntries = Object.entries(tasks);

  const timelineOptions = timelines?.reduce((previous, { id, projectName }) => {
    return { ...previous, [id]: { value: id, text: projectName } };
  }, {});

  const timelineOptionsArray = [
    { value: "all", text: "All" },
    { value: "not-assigned", text: "Not Assigned" },
    ...Object.values(timelineOptions),
  ];

  const [filterOption, setFilterOption] = useState(
    searchParams.get("filter") ?? timelineOptionsArray[0]?.value
  );

  if (filterOption !== "all") {
    taskEntries = taskEntries.filter(([, task]) => {
      if (filterOption === "not-assigned") {
        return !task?.timeline;
      }

      return task?.timeline === filterOption;
    });
  }

  useEffect(() => {
    const allTasks = JSON.parse(localStorage.getItem("tasks")) || {};
    let timelineTasks = allTasks;

    if (filterOption == "all") {
      return setTasks(allTasks);
    }

    if (filterOption == "not-assigned") {
      timelineTasks = Object.values(allTasks).filter((task) => !task?.timeline);
      return setTasks(timelineTasks);
    }

    timelineTasks = Object.values(allTasks).filter(
      (task) => task.timeline === filterOption
    );

    return setTasks(timelineTasks);
  }, [filterOption]);

  const updateTasks = () => {
    const allTasks = JSON.parse(localStorage.getItem("tasks")) || {};
    let timelineTasks = allTasks;

    if (filterOption == "all") {
      return setTasks(allTasks);
    }

    if (filterOption == "not-assigned") {
      timelineTasks = Object.values(allTasks).filter((task) => !task?.timeline);
      return setTasks(timelineTasks);
    }

    timelineTasks = Object.values(allTasks).filter(
      (task) => task.timeline === filterOption
    );

    return setTasks(timelineTasks);
  };

  const filteredTasks = Object.fromEntries(taskEntries);

  return (
    <div className="pb-6 px-4">
      <div className="flex flex-col gap-3">
        <h2>Tasks</h2>
        <QuickAddTask
          timelineId={filterOption}
          expanded
          updateTasks={updateTasks}
        />
        <div className="flex justify-end">
          <Dropdown
            options={timelineOptionsArray}
            value={filterOption}
            onChange={(value) => {
              setSearchParams({ filter: value });
              setFilterOption(value);
            }}
            prefix={"Show: "}
          />
        </div>
        <TaskList
          tasks={filteredTasks}
          timelines={timelines}
          displayAssignedTimeline
        />
      </div>
    </div>
  );
};

export default TaskListContainer;
