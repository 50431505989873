export default function TimelineMeter({
  days,
  end,
  excludeWeekends,
  isToday,
  pastDays,
  start,
}) {
  return (
    <div
      className="text-xs bg-gray-900 border boder-solid border-indigo-800 rounded p-2 gap-2"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div>Start: {start.toLocaleDateString()}</div>
      <div
        style={{
          flexGrow: 1,
          display: "grid",
          alignContent: "center",
          gridTemplateColumns: "repeat(auto-fit, minmax(5px, 1fr))",
          gap: "4px",
        }}
      >
        {days.map((day, index) => (
          <div
            className={`rounded 
              ${day.isWeekend && excludeWeekends && "bg-indigo-900"}
              ${
                isToday(day.date)
                  ? "bg-green-300"
                  : index < pastDays
                  ? "bg-red-300"
                  : "bg-gray-400"
              }
              `}
            key={index}
            style={{
              minWidth: "5px",
              height: day.isWeekend && excludeWeekends ? "5px" : "10px",
            }}
          />
        ))}
      </div>
      <div>End: {end.toLocaleDateString()}</div>
    </div>
  );
}
