export const TEXT_CONSTANTS = {
  global: {
    // other global constants...
  },
  forms: {
    submitButton: "Submit",
    // other form constants...
  },
  descriptions: {
    excludeWeekends:
      "Weekends are omitted from timeline completion percentage and length calculations",
    // other description constants...
  },
  // other categories...
};
