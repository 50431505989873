import { TEXT_CONSTANTS } from "./textConstants";

export default function TimelineSummary({
  daysTillProjectStart,
  excludeWeekends,
  pastDays,
  pastDaysExcludingWeekends,
  projectEnded,
  projectStarted,
  totalDays,
  totalDaysExcludingWeekends,
}) {
  const projectInProgress = projectStarted && !projectEnded;

  const totalToUseForPercentage = excludeWeekends
    ? totalDaysExcludingWeekends
    : totalDays;

  const pastDaysToUseForPercentage = excludeWeekends
    ? pastDaysExcludingWeekends
    : pastDays;

  const percentageComplete = projectEnded
    ? 100
    : projectStarted
    ? Math.round((pastDaysToUseForPercentage / totalToUseForPercentage) * 100)
    : 0;

  const daysRemaining =
    (projectStarted || projectEnded) && excludeWeekends
      ? totalDaysExcludingWeekends - pastDaysExcludingWeekends
      : !projectStarted
      ? daysTillProjectStart
      : totalDays - pastDays;

  const timelineTotalDays = excludeWeekends
    ? totalDaysExcludingWeekends
    : totalDays;

  const status = projectInProgress
    ? "In Progress"
    : projectEnded
    ? "Ended"
    : "Not Started";

  const gridCols = excludeWeekends ? 5 : 4;

  const remaningHeading =
    !projectInProgress && !projectEnded
      ? "Starts in"
      : projectEnded
      ? "Completed"
      : "Remaining";

  return (
    <div className="flex flex-wrap justify-between gap-x-4 gap-y-2 text-sm px-2 mb-3">
      <div>
        Status
        <div className="text-indigo-200">{status}</div>
      </div>
      <div>
        {remaningHeading}
        <div className="text-indigo-200">{getDayOrDays(daysRemaining)}</div>
      </div>
      <div>
        Total
        <div className="text-indigo-200">{getDayOrDays(timelineTotalDays)}</div>
      </div>
      <div>
        Time Completion
        <div className="text-indigo-200">{percentageComplete} %</div>
      </div>
      {excludeWeekends && (
        <div>
          Excluding
          <div className="text-indigo-200">Weekends</div>
        </div>
      )}
    </div>
  );
}

function getDayOrDays(days) {
  const absoluteDays = Math.abs(days);
  const dayOrDays = absoluteDays === 1 ? "day" : "days";
  const ago = days < 0 ? " ago" : "";
  return `${absoluteDays} ${dayOrDays}${ago}`;
}
